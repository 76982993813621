import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Breakpoint, breakpointsDesc, getWidth} from "../../utils/breakpoint";

type Props = Omit<React.ComponentProps<'video'>, 'src' | 'ref'> &
    { sources: Partial<Record<Breakpoint, string>>; };

export default forwardRef(function ResponsiveVideo({ sources, ...videoProps }: Props, ref: ForwardedRef<HTMLVideoElement>) {
    const [videoSrc, setVideoSrc] = useState<string>();
    const [lastTime, setLastTime] = useState(0);
    const [wasPlaying, setWasPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    useImperativeHandle(ref, () => videoRef.current!, []);

    function refreshVideo() {
        for (const breakpoint of breakpointsDesc) {
            const viewportWidth = getWidth(breakpoint);
            const src = sources[breakpoint];
            // <source> elements are not re-evaluated when the viewport size changes, but changing the src attribute on the <video> element works
            if (window.innerWidth >= viewportWidth && src) {
                if (src !== videoRef.current?.currentSrc) {
                    setWasPlaying(!videoRef.current?.paused)
                    setLastTime(videoRef.current?.currentTime ?? 0);
                    setVideoSrc(src);
                }
                break;
            }
        }
    }

    useEffect(() => {
        refreshVideo();
        window.addEventListener('resize', refreshVideo);
        return () => window.removeEventListener('resize', refreshVideo);
    }, [sources]);

    useEffect(() => {
        if (wasPlaying && videoRef.current?.paused) {
            // restore video to previous state after switch
            videoRef.current.currentTime = lastTime;
            void videoRef.current.play();
        }
    }, [videoSrc, lastTime, wasPlaying])

    return <video src={videoSrc} ref={videoRef} {...videoProps} >
        {breakpointsDesc.map(breakpoint => {
            const src = sources[breakpoint];
            const viewportWidth = getWidth(breakpoint);
            return <React.Fragment key={breakpoint}>
                {src && <source src={src} media={viewportWidth > 0 ? `(min-width: ${getWidth(breakpoint)}px)` : undefined} />}
            </React.Fragment>;
        })}
    </video>;
});
